import { CapillaryRefillTime, Mm } from 'types.d';

export default {
  DEFAULT_LIMIT: 10,
  PAGE_SIZE: 10,
  DEBOUNCE_DElAY: 500,
  DATE_FORMAT: 'dd MMM yyyy',
  TIME_FORMAT: 'hh:mm a',
  DATE_AND_TIME_FORMAT: 'dd MMM yyyy HH:mm',
  LOCALIZED_TIME_FORMAT: 'p',
  SHORT_DATE_FORMAT: 'ddMMyy',
  SHORT_MONTH_FORMAT: 'MMM',
  ROUTINE_TEST_DATE_FORMAT: 'MMMM yyyy',
  CHARTS_DEFAULT_DONATIONS_LIMIT: 12,
  CHARTS_DATE_PICKER_FORMAT: 'yyyy/MM',
  LABWORK_DURATION: 340, //in days
  APPOINTMENT_TIME_FORMAT: 'p',
  SCHEDULE_DATE_FORMAT: 'iii, dd MMM',
  EXPECTED_MLS_WHEN_BIG_DONOR: 450,
  EXPECTED_MLS_WHEN_SMALL_DONOR: 250,
  BIG_DONOR_MIN_WEIGHT: 25, //in kgs
  MIN_CAT_WEIGHT: 9.5, //in lbs
  MIN_DOG_WEIGHT: 44, //in lbs
  MIN_DOG_WEIGHT_KG: 15.87,
  MIN_CAT_WEIGHT_KG: 4.3,
  BLO0D_VOLUME_PER_KG: 85, //in mls
  BLO0D_VOLUME_PERCENTAGE: 20, //in mls
  DEFAULT_VETERINARIAN: 'Melanie S. Galanis, DVM',
  WEEK_STARTS_ON: 1 as 0 | 1 | 3 | 2 | 4 | 5 | 6,
  PHONE_NUMBER_MASK: '(999) 999-9999',
  DEFAULT_CAPILLARY_REFILL_TIME: CapillaryRefillTime.BetweenOneAndTwo,
  DEFAULT_MUCOUS_MEMBRANE: Mm.Pink,
  LAST_LABWORK_IN_DAYS_LIMIT: 350,
};
