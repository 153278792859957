import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';
import { getEntries } from 'utils/object/getEntries';

const donorHeadersConfig = {
  id: 'id',
  name: 'name',
  user: {
    lastName: 'lastName',
    id: 'ownerId',
  },
  location: 'location',
  region: 'region',
  age: 'age',
  neutered: 'neutered',
  weight: 'weight',
  primaryVeterinarian: 'primaryVeterinarian',
  species: 'species',
  gender: 'gender',
  bloodType: 'bloodType',
  status: 'status',
  breed: 'breed',
  color: 'color',
  shortId: 'shortId',
  becameReservedDonorDate: 'becameReservedDonorDate',
  rabiesDate: 'rabiesDate',
  distemperDate: 'distemperDate',
  retiredDate: 'retiredDate',
  nextDonationDate: 'nextDonationDate',
  totalCollected: 'totalCollected',
  labWorkDue: 'labWorkDue',
  annualDVMComplete: 'Annual DVM complete',
  microchipNumber: 'microchipNumber',
  approvedDonationML: 'approvedDonationML',
  donationMLApprovedAmount: 'donationMLApprovedAmount',
  donationFrequency: 'donationFrequency',
  acceptSimparicaTrio: 'acceptSimparicaTrio',
  notes: 'notes',
};

const donorHeadersConfigEntries = getEntries(donorHeadersConfig);

export const donorReportHeaders: LabelKeyObject[] =
  donorHeadersConfigEntries.map(([key, label]) => ({ label, key }));
